import { useEffect, useState } from "react";
import Header from "../../components/Header/";
import RespremDownload from "../../components/RespremDownload"
import authService from "../../services/auth.services";
import { Navigate } from 'react-router-dom';
import Footer from "../../components/Footer";

import RespremLogo from "../../components/utils/RespremLogo";

const DownloadFilePage = () => {
    const [redirectTo, setRedirectTo] = useState(false);
    const [name, setName] = useState("");

    useEffect(() => {

        const fetchAuthUser = async () => {
            let loggedUser = await authService.getLoggedUser();
            let userName = await authService.getNomeUser();

            if (!loggedUser) {
                setRedirectTo(true);
            }

            setName(userName);
        }

        fetchAuthUser();
    }, []);

    if (redirectTo) {
        return (
            <Navigate to="/" />
        )
    }

    return (
        <div>
            <Header name={name} />

            <div style={{marginTop:"2em"}} className="download-container">
                <RespremLogo />
                <RespremDownload />
            </div>

            <Footer />
        </div>
    )
}

export default DownloadFilePage;