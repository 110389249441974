import {BrowserRouter, Routes, Route} from 'react-router-dom';
import NotFound from './pages/notfound.js'

import LoginPage from "./pages/LoginPage";
import DashboardPage from './pages/DashboardPage/index.js';
import DownloadFilePage from './pages/DownloadFilePage/index.js';
import RecoveryPasswordPage from './pages/RecoveryPasswordPage/index.js';

function App() {

  return (
    <BrowserRouter>
        <Routes>
            <Route path='/' element={<LoginPage />}/>
            <Route path='/dashboard' element={<DashboardPage />}/>
            <Route path='/resprem-download' element={<DownloadFilePage />}/>
            <Route path='/recuperar-senha' element={<RecoveryPasswordPage />}/>
            <Route path='*' element={<NotFound />}/>
        </Routes>
    </BrowserRouter>
  );
}

export default App;
