import { useState } from "react";
import Dropzone from 'react-dropzone';
import Request from "../../services/Request";
import { Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import ProgressBar from "../ProgressBar";
import authService from "../../services/auth.services";

import "react-toastify/dist/ReactToastify.css";
import "./FileUpload.scss";

import { respremApiServices } from "../../services/respremApi.services";
import RespremLogo from "../utils/RespremLogo";

const FileUpload = () => {

    const [files, setFiles] = useState([]);
    const [targetFile, setTargetFile] = useState(null);
    const [message, setMessage] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [modal, setModal] = useState(false);
    const [fileLoaded, setFileLoaded] = useState(false);
    const [reader, setReader] = useState(null);
    const [showImage, setShowImage] = useState(true);
    const [test, setTest] = useState(false);

    const request = new Request();

    const onDrop = (files) => {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);

        setFiles(files);
        setTargetFile(files[0]);
        setReader(reader);
        setShowImage(true);
        setMessage('');
        setFileLoaded(true);
    };

    const responseJson = (response) => {
        response.then(result => {
            setMessage(result.message);
            setRedirect(true);
        });
    };

    const submit = async () => {

        if (fileLoaded) {
            if (test) {
                toast.warning("Erro, por favor contate o administrador da plataforma");
            } else {
                const userID = await authService.getIdUser();
                await respremApiServices.resetProgress(userID);

                setTest(true);
                setModal(true);

                request.uploadFile(targetFile)
                    .then(response => {
                        responseJson(response.json());
                        setRedirect(true);
                    }).catch(error => {
                        responseJson(error.json());
                    });
            }
        } else {
            toast.warning("Arquivo obrigatório!");
        }
    }

    if (modal) {
        return (
            <div className="modal-loading-breaking"
                open={modal}
                on={'click'}
                modal
                nested
                closeOnDocumentClick={false}
                closeOnEscape={false}
            >
                <ProgressBar />
            </div>
        )

    }

    if (redirect) {
        return <Navigate to="/resprem-download" />;
    }

    const file = files.map(file => (
        <li key={file.name}>
            {file.name}
        </li>
    ));

    return (
        <>
            <Dropzone 
                onDrop={onDrop}
                accept={{ 'application/octet-stream': ['.dbf'] }}
                multiple={false}
            >
                {({ getRootProps, getInputProps, isDragActive }) => (
                    <div className="dropzone-container">
                        <div {...getRootProps()} >
                            <input {...getInputProps()} />
                            <div className="text-center">
                                {isDragActive ? (
                                    <div className="text-center-active">
                                        <div className='column-active'>
                                            <h4> Solte o arquivo aqui </h4>
                                        </div>

                                    </div>
                                ) : (
                                    <div className="text-center" >
                                        <div className='column'>
                                            <RespremLogo />
                                            <h4> Clique para selecionar ou arraste o arquivo <br /> RESPREM aqui </h4>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <p className="App-intro">
                            <button id="baixar" type="button" name="botao" value="pois" onClick={submit} >
                                Quebrar Arquivo
                            </button>
                            <div className="files-list" style={files.length > 0 ? { display: 'flex' } : { display: "none" }}>
                                <p>Arquivo:</p>
                                <ul>
                                    {
                                        files.map((file, index) =>
                                            <li key={file.name}>
                                                {file.name}
                                                <button
                                                    className="remove-button"

                                                    onClick={() => {
                                                        const updatedFiles = files.filter((_, i) => i !== index);
                                                        setFiles(updatedFiles);
                                                        setFileLoaded(false);
                                                    }
                                                    }>
                                                    Remover
                                                </button>
                                            </li>)
                                    }
                                </ul>
                            </div>
                        </p>
                    </div>
                )}
            </Dropzone>
            <ToastContainer draggable closeButton={false} />
        </>
    );
}

export default FileUpload;