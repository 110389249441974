import { useEffect, useState } from "react";
import Header from "../../components/Header/index.js";
import authService from '../../services/auth.services.js';
import { Navigate, Outlet } from 'react-router-dom';

import FileUpload from "../../components/FileUpload";

import "./DashboardPage.scss";
import Footer from "../../components/Footer/index.js";

const DashboardPage = () => {
  const [redirectTo, setRedirectTo] = useState(false);
  const [name, setName] = useState("");

  useEffect(() => {

    const fetchAuthUser = async () => {
      let loggedUser = await authService.getLoggedUser();
      let userName = await authService.getNomeUser();

      if (!loggedUser) {
        setRedirectTo(true);
      }

      setName(userName);
    }

    fetchAuthUser();
  }, []);

  if (redirectTo) {
    return (
      <Navigate to="/" />
    )
  }

  return (
    <>
      <Header name={name} />

      <div className="dashboard-container">
        <Outlet />

        <div className="file-upload-container">
          <FileUpload />
        </div>
      </div>

      <Footer />
    </>
  )
}

export default DashboardPage;