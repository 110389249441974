import ServicesList from "./ServiceList";
import authService from "../../services/auth.services";

import "./Header.mobile.scss";
import "./Header.scss";

import { Link } from 'react-router-dom';

const Header = (props) => {

  return (
    <header>
      <Link to={'/dashboard'}>
        <img src="/assets/images/utils/logo-principal.png" />
      </Link>

      <div className="unlog-container">
        <p>{props.name}</p>
        <button onClick={authService.cleanLoggedUser} className="unlog-button">Sair</button>
      </div>
      

      <nav className="navigation">
        <ul>
          <ServicesList />          
        </ul>
      </nav>
    </header>
  )
}

export default Header;