import "./ProgressBar.scss";
import "./ProgressBar.mobile.scss";

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import authService from "../../services/auth.services";
import RespremLogo from "../utils/RespremLogo";

const ProgressBar = () => {

    const [progress, setProgress] = useState(0);
    const navigate = useNavigate();
    const [message, setMessage] = useState('');

    const startPercentage = async () => {
        try {
            const idUser = authService.getIdUser();
            if (!idUser) {
                throw new Error('ID do usuário não encontrado');
            }
            // No servidor
            // const response = await fetch(`https://api.krltech.com.br/contagem?user_id=${idUser}`, {
            //     method: 'GET',
            //     headers: {
            //         'Content-Type': 'application/json'
            //     }
            // });
            //  No local

            const response = await fetch(`${process.env.REACT_APP_API}/contagem?user_id=${idUser}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`Erro ao buscar dados: ${response.statusText}`);
            }

            const data = await response.json();

            const message = data.mensagem;
            if (message === 'ok') {
                setMessage(data.mensagem);
            }
            setProgress(data.progresso);

        } catch (error) {
            console.error('Erro ao iniciar porcentagem:', error);
        }
    };

    useEffect(() => {
        const percentageInterval = setInterval(() => {
            startPercentage();
        }, 8000);

        if (message === 'ok') {
            navigate("/resprem-download");
            clearInterval(percentageInterval);
        }

        return () => clearInterval(percentageInterval);
    }, [message]);

    const preparingFile = () => {
        const fileZero = progress;

        if (fileZero == 0) {
            return (
                <div className="center">
                    <div className='resprem'>
                        <h1>RESPREM</h1>
                        <img src="/assets/images/dashboard/resprem-icon.png" />
                    </div>
                    <div className="progress-label">Organizando seu arquivo DBF, aguarde <br /> um momento.</div>
                    <div className="loader"></div>
                </div>
            )

        } else {
            return (
                <div className="loader-container">
                    <div className="center">
                        <RespremLogo />
                        <div className="progress-label">Quebrando o arquivo. Isso pode demorar <br /> alguns instantes!</div>
                    </div>

                    <div className="center-column">
                        <div className="progress-bar">
                            <div className="progress-bar-fill" style={{ width: `${progress}%` }}></div>
                        </div>
                        <div className="progress-bar-percentage"> {progress}% </div>
                    </div>
                </div>
            )
        }
    }

    return (
        preparingFile()
    );
}

export default ProgressBar;