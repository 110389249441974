import "./RespremLogo.scss";

const RespremLogo = () => {
    return (
        <div className='resprem'>
            <h1>RESPREM</h1>
            <img src="/assets/images/dashboard/resprem-icon.png" />
        </div>
    )
}

export default RespremLogo;