import React, { useState } from "react";

import authService from "../../services/auth.services.js";
import { Navigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";

import "./RecoveryPasswordForm.scss";

const RecoveryPasswordForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [redirectTo, setRedirectTo] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState(null);

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    const sendLogin = async (event) => {
        event.preventDefault();

        // if (!recaptchaToken) {
        //     alert("Por favor, complete o reCAPTCHA.");
        //     return;
        // }

        let data = {
            email: email,
            password: password
        }

        try {
            let res = await authService.authenticate(data)

            authService.setLoggedUser(res.data)
            setRedirectTo({ redirectTo: true })

        } catch (error) {
            toast.error("Erro ao efetuar o login");
        }
    };

    if (redirectTo) {
        return (
            <Navigate to="/dashboard" />
        );
    }

    return (
        <>
            <div className="login-container">
                <section>
                    <img src="/assets/images/utils/logo-principal.png" onClick={() => window.location.reload()} className="logo" alt="Logo KRL Services" />

                    <p>
                        Não se preocupe! Iremos te enviar um e-mail que permitirá redefinir sua senha.
                    </p>

                    <form onSubmit={sendLogin}>
                        <fieldset>

                            <span>
                                E-mail
                            </span>

                            <img src="/assets/images/login/email-label-icon.png" alt="Ícone de E-mail" />

                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />

                        </fieldset>

                        <ReCAPTCHA
                            key={email}
                            sitekey="6Lf-IV0qAAAAAB_-usYpv7S1rvtN55q7nw-uBaW0"
                            onChange={handleRecaptchaChange}
                            className="ReCAPTCHA"
                            onErrored={() => alert('O reCAPTCHA falhou ao carregar. Por favor, recarregue a página.')}
                        />

                        <button type="submit">Enviar</button>
                    </form>

                </section>
                <ToastContainer draggable closeButton={false} />
            </div>
        </>
    )
}

export default RecoveryPasswordForm;