import "./RespremDownload.scss";

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Navigate } from 'react-router-dom';

import authService from "../../services/auth.services.js";

const RespremDownload = () => {
    const [redirectTo, setRedirectTo] = useState(false);

    useEffect(() => {

        const fetchAuthUser = async () => {
            let loggedUser = await authService.getLoggedUser();

            if (!loggedUser) {
                setRedirectTo(true);
            }
        }

        fetchAuthUser();
    }, []);

    const Zip = async () => {
        try {
            const idUser = authService.getIdUser();

            const response = await axios.get(`${process.env.REACT_APP_API}/download/Arquivos Gerados.zip`, {
                responseType: 'blob',
                headers: {
                    'User-ID': idUser
                }
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Arquivos Gerados.zip');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Erro ao baixar o arquivo:', error);
        }
    }

    if (redirectTo) {
        return (
            <Navigate to="/" />
        )
    }

    return (
        <div id="body">
            <div class="concluido">
                <p>Quebra realizada com sucesso! <br /> Arquivos prontos para download.  </p>
            </div>
            <div className="download-area">
                <img src="/assets/images/download/folder-icon.png" alt=" iconeDownload" class="iconeDownload" />
                <div class="buttons">
                    <button class="botaoDownload" id="botaoDownload" type="button" value="botao" onClick={Zip}>Download</button>
                    <Link to={'/dashboard'}><button class="botaoCancelar" id="botaoCancelar" type="button" value="botao">Nova Quebra</button></Link>
                </div>
            </div>
        </div>
    )
}

export default RespremDownload;