import "./ServiceList.scss";

import { useState } from "react";
import { Link } from 'react-router-dom';

const ServicesList = () => {

    const [openItem, setOpenItem] = useState(null);

    const toggleMenu = (item) => {
        setOpenItem(openItem === item ? null : item);
    };

    return (
        <li
            onClick={() => toggleMenu("OPERACIONAL")}
            className={
                `services-list ${openItem === "OPERACIONAL" ? "show" : ""}`
            }
        >
            <p>Serviços</p>

            <span>
                <img src="/assets/images/utils/arrow-down.png" className="arrow-down" />
            </span>

            <div className="tools-list">
                <ul>
                    <li>
                        <p>Operacional</p>
                        <ul>
                            <Link to={'/dashboard'}>
                                <li>
                                    <img src="/assets/images/utils/resprem-document-icon.png" className="resprem-document-icon" />
                                    Quebra de Arquivos RESPREM
                                </li>
                            </Link>
                        </ul>
                    </li>
                </ul>
            </div>
        </li>
    )
}

export default ServicesList;