import React, { useState } from "react";

import authService from "../../services/auth.services.js";
import { Navigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from 'react-google-recaptcha';

import "./LoginForm.scss";

const LoginForm = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [redirectTo, setRedirectTo] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState(null);

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    const sendLogin = async (event) => {
        event.preventDefault();

        if (!recaptchaToken) {
            alert("Por favor, complete o reCAPTCHA.");
            return;
        }

        let data = {
            email: email,
            password: password
        }

        try {
            let res = await authService.authenticate(data)

            authService.setLoggedUser(res.data)
            setRedirectTo({ redirectTo: true })

        } catch (error) {
            toast.error("Erro ao efetuar o login");
        }
    };

    if (redirectTo) {
        return (
            <Navigate to="/dashboard" />
        );
    }

    return (
        <>
            <div className="login-container">
                <section>
                    <img src="/assets/images/utils/logo-principal.png" className="logo" alt="Logo KRL Services" onClick={
                        () => window.location.reload()
                    } />

                    <form onSubmit={sendLogin}>
                        <fieldset> 
                            <span>
                                E-mail
                            </span>
                            
                            <img src="/assets/images/login/email-label-icon.png" alt="Ícone de E-mail" />

                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />

                        </fieldset>

                        <fieldset>
                            <span>
                                Senha
                            </span>

                            <img src="/assets/images/login/password-label-icon.png" alt="Ícone de Senha" />

                            <input
                                id="password"
                                type="password"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                        </fieldset>

                        <span className="forgot-password" onClick={
                            ()=>alert("Entre em contato com o suporte:\nsuporte@krlservices.com.br")
                        }>Esqueceu sua senha?</span>

                        <ReCAPTCHA
                            key={email}
                            sitekey={process.env.REACT_APP_SITEKEY}
                            // sitekey="6LcEYGEqAAAAAFZHyUgrFlJ04l0peh7d0IxevPdN"
                            onChange={handleRecaptchaChange}
                            className="ReCAPTCHA text-align-center"
                            onErrored={() => alert('O reCAPTCHA falhou ao carregar. Por favor, recarregue a página.')}
                        />

                        <button type="submit">Entrar</button>
                    </form>

                </section>
                <ToastContainer draggable closeButton={false} />
            </div>
        </>
    );
}

export default LoginForm;