import "./Footer.scss";

const Footer = () => {
  return (
    <footer>
      <p>© 2024 KRL Services. Todos os direitos reservados.</p>
    </footer>
  )
}

export default Footer;