import React from 'react';
import styled from "styled-components";

const NotFound_style = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    margin: 0;
    width: 100%;
    height: 740px;
    font-family: Montserrat;
    font-style: normal;
    color: white;

    img{
      width: 100px;
      height: auto;
    }

    h2{
      margin-top: -10px;
    }
`;

export default function NotFound() {
  return(
    <NotFound_style>
        <img src="/assets/images/utils/logo-icon.png" alt="Logo KRL" id="img2" /> 
        <h1>404 not found</h1>
        <h2>Entre em contato com o suporte.</h2>
    </NotFound_style>
  )
}